import * as React from "react";
import styled from "styled-components";

import breakpoints from "../variables/breakpoints";

const ConcertWrapper = styled.div`
    margin: 1rem 0;
`

const ConcertTitleRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    border-top: ${({index}) => index? "1px solid #d5d5d5" : "0"};
    padding-top: 1rem;

    @media(min-width: ${breakpoints.large}) {
        flex-wrap: nowrap;
    }

    > * + * {
        margin-left: 1rem;
    }
`

const ConcertDate = styled.div`
    border: 1px solid #ddd;
    border-radius: 2px;
    padding: 0.75rem;
    font-weight: bold;
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 10%);
`

const ConcertTitle = styled.h3`
    margin-top: 0;
    margin-bottom: 0;
`

const ConcertArtistTag = styled.div`
    padding: 0.25rem;
    border: 1px solid rgb(0 73 75 / 30%);
    border-radius: 5px;
    background: rgb(0 73 75 / 5%);
    color:rgb(0 73 75 / 100%);
`

const ConcertDetails = styled.div`
    flex-grow: 1;
    order: 1;
    width: 100%;
    margin-left: 0;
    margin-top: 1rem;

    @media(min-width: ${breakpoints.large}) {
        order: 0;
        flex-shrink: 1;
        width: auto;
        margin-left: 1rem;
    }
`

const ConcertPlace = styled.div`
    font-weight: bold;
`

const Concert = ({ concert, index }) => {
    const date = new Date(concert.date);
    
    return (
        <ConcertWrapper>
            <ConcertTitleRow index={index}>
                <ConcertDate>{date.toLocaleDateString('fi-FI')}</ConcertDate>
                <ConcertDetails>
                    <ConcertTitle>{concert.title}</ConcertTitle>
                    <ConcertPlace>Klo {date.toLocaleTimeString('fi-FI', { hour: '2-digit', minute: '2-digit' })} {concert.placeName}</ConcertPlace>
                    <div>{concert.address}</div>
                    <div
                        className="concertBody"
                        dangerouslySetInnerHTML={{
                            __html: concert.body?.childMarkdownRemark.html,
                        }}
                    />
                </ConcertDetails>
                { concert.artists.map(artist => (
                    <ConcertArtistTag>{artist}</ConcertArtistTag>
                )) }
            </ConcertTitleRow>
        </ConcertWrapper>
    )
}

export default Concert
