import * as React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import styled from "styled-components"

import Layout from "../components/layout";
import Concert from "../components/concert";

const PastConcertHeader = styled.h2`
    margin-top: 1rem;
    border-top: 1px solid #d5d5d5;
    padding-top: 2rem;
`

const IndexPage = ({ data }) => {
  const bodyText = data.text.body.childMarkdownRemark.html;
  const headerPicture = data.text.headerPicture;
  const concerts = data.concerts.nodes;

  const upcomingConcerts = concerts.filter(concert => new Date(concert.date) > Date.now())
  const pastConcerts = concerts.filter(concert => new Date(concert.date) < Date.now()).reverse();

  return (
    <Layout headerPicture={headerPicture}>
      <Helmet>
        <title>{data.text.title}</title>
      </Helmet>
      <div
        className="body"
        dangerouslySetInnerHTML={{
          __html: bodyText,
        }}
      />
      { upcomingConcerts.map((concert, index) => (
          <Concert concert={concert} index={index} />
      ))}
      <PastConcertHeader>Menneet konsertit:</PastConcertHeader>
      { pastConcerts.map((concert, index) => (
          <Concert concert={concert} index={index} />
      ))}      
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    text: contentfulPage(path: { eq: "konsertit" }) {
      title
      body {
        childMarkdownRemark {
          html
        }
      }
      headerPicture {
        title
        description
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        file {
          details {
            image {
              width
              height
            }
          }
          url
        }
      }
    }
    concerts: allContentfulConcert(sort: {fields: date, order: ASC}) {
      nodes {
        title
        date
        placeName
        address
        artists
        body {
            childMarkdownRemark {
              html
            }
          }
      }
    }
  }
`;
